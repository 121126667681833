import './Footer.scss';

const Footer = () => {
    return (
        <footer>
            <p>© 2022 Fredy Rodrigo - Desarrollador web</p>
        </footer>
    )
}

export default Footer;