import './Welcome.scss'
import { Link } from 'react-router-dom'; 

const Welcome = () => {
    return (
        <section className='welcome'>
            <div className='welcome__content'>
                <div className='welcome__content__info'>
                    <div className='welcome__content__info__text'>
                        <p>Hola, soy</p>
                        <p>Fredy Rodrigo</p>
                        <p>Desarrollador web</p>
                    </div>
                    <div className='welcome__content__info__contact'>
                        <Link to='/contacto'>
                            Contáctame
                            <img src='./assets/icons/arrow-right.svg' alt='icono de flecha a la derecha'/>
                        </Link>
                    </div>
                </div>
                {/* <div className='welcome__content__img'>
                    <img src='./assets/images/welcome.webp' alt=''/>
                </div> */}
            </div>
        </section>
    )
}

export default Welcome;