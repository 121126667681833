import './FloatIcons.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faSun } from '@fortawesome/free-solid-svg-icons';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';

const FloatIcons = () => {
    const {switchTheme} = useContext(ThemeContext);

    const [scroll, setScroll] = useState('none');


    window.onscroll = () => {
        window.scrollY > 700? setScroll('block'):setScroll('none');
    }
    
    const subir = () => {
        window.scrollTo({
            top:0,
            behavior: 'smooth'
        })
    }

    return (
        <div className='icons'>
            <div className='icons__group1'>
                <div className='icons__group1__home'>
                    <Link to='/'>
                        <FontAwesomeIcon icon={faHome} className='icon icon-home gray-icon'/>
                    </Link>
                </div>
                <div className='icons__group1__theme'>
                    <FontAwesomeIcon icon={faSun} className='icon icon-theme gray-icon'  onClick={()=>switchTheme()}/>
                </div>
            </div>
            <div className='icons__group2'>
                <div className='icons__group2__up' onClick={()=>subir()} style={{display:scroll}}>
                    <FontAwesomeIcon icon={faChevronCircleUp} className='icon icon-up gray-icon gray-icon'/>
                </div>
            </div>
        </div>
    )
}

export default FloatIcons;