import './Project.scss';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons'; 
import { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';

const Project = ({data}) => {
    const {theme} = useContext(ThemeContext);
    const [showModal, setShowModal] = useState(false);

    return (
        <div className='project'>
            <img src={`./assets/images/${data.img}`} alt='portada de cineplus' onClick={()=>setShowModal(true)}/>
            <span onClick={()=>setShowModal(true)}>Vista previa</span>

            {
                showModal && 
                <div className='project__vista'>
                    <div className='project__vista__modal'>
                        <div className='project__vista__modal__content' style={{
                            backgroundColor: theme? '#f3f3f3': '#19191a', 
                            color: theme? '$dark-text':'white'
                            }}>
                            <div className='version'>
                                <div className='version__desktop'>
                                    <div className='version__desktop__title'>Desktop</div>
                                    <img src={`./assets/images/${data.desktop}`} alt='diseño de la pagina en version desktop'/>
                                </div>
                                <div className='version__mobile'>
                                    <div className='version__mobile__title'>Móvil</div>
                                    {
                                        data.mobile? 
                                        <img src={`./assets/images/${data.mobile}`} alt='diseño de la pagina en version movil'/>:
                                        <p>Diseño mobile en desarrollo</p>
                                    }
                                </div>
                            </div>
                            <div className='links'>
                                <div className='links__item'>
                                    <a href={data.repo} target='_blank' rel='noreferrer' style={{
                                        color: theme? '$dark-text':'white'
                                    }}>
                                        <FontAwesomeIcon icon={faGithub} className='icon'/>
                                        Repositorio
                                    </a>
                                </div>
                                <div className='links__item'>
                                    <a href={data.link} target='_blank' rel='noreferrer' style={{
                                        color: theme? '$dark-text':'white'
                                    }}>
                                        <FontAwesomeIcon icon={faLink} className='icon'/>
                                        Enlace
                                    </a>
                                </div>
                            </div>
                            <div className='close' onClick={()=>setShowModal(false)}>
                                <FontAwesomeIcon icon={faXmark} className='close__icon'/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Project;