import './Skills.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5 } from '@fortawesome/free-brands-svg-icons';
import { faCss3Alt } from '@fortawesome/free-brands-svg-icons';
import { faJs } from '@fortawesome/free-brands-svg-icons';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { faSass } from '@fortawesome/free-brands-svg-icons';
import { faFigma } from '@fortawesome/free-brands-svg-icons';
import { faBootstrap } from '@fortawesome/free-brands-svg-icons';
import { faGitAlt } from '@fortawesome/free-brands-svg-icons';

const Skills = () => {
    return (
        <section className='skills'>
            <div className='skills__content'>
                <div className='skills__content__subtitle text'>
                    - mis habilidades -
                </div>
                <h2 className='skills__content__title'>Tecnologías que manejo</h2>
                <div className='skills__content__technologies'>
                    <div className='skills__content__technologies__items'>
                        <div className='skill-item'>
                            <FontAwesomeIcon icon={faHtml5} className='icon gray-icon'/>
                            <p>HTML</p>
                        </div>
                        <div className='skill-item'>
                            <FontAwesomeIcon icon={faCss3Alt} className='icon gray-icon'/>
                            <p>CSS</p>
                        </div>
                        <div className='skill-item'>
                            <FontAwesomeIcon icon={faJs} className='icon gray-icon'/>
                            <p>JavaScript</p>
                      </div>
                        <div className='skill-item'>
                            <FontAwesomeIcon icon={faReact} className='icon gray-icon'/>
                            <p>React JS</p>
                        </div>
                        <div className='skill-item'>
                            <FontAwesomeIcon icon={faSass} className='icon gray-icon'/>
                            <p>Sass</p>
                        </div>
                        <div className='skill-item'>
                            <FontAwesomeIcon icon={faFigma} className='icon gray-icon'/>
                            <p>Figma</p>
                        </div>
                        <div className='skill-item'>
                            <FontAwesomeIcon icon={faBootstrap} className='icon gray-icon'/>
                            <p>Bootstrap</p>
                        </div>
                        <div className='skill-item'>
                            <FontAwesomeIcon icon={faGitAlt} className='icon gray-icon'/>
                            <p>Git</p>
                       </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Skills;