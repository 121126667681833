import Welcome from '../components/Welcome/Welcome';
import About from '../components/About/About';
import Proyects from '../components/Proyects/Proyects';
import Skills from '../components/Skills/Skills';
import Contact from '../components/Contact/Contact';
import { useContext, useEffect } from 'react';
import { ThemeContext } from '../context/ThemeContext';

const PageHome = () => {
    const {theme, darkTheme, lightTheme} = useContext(ThemeContext);

    useEffect(() => {
        if(theme) {
            lightTheme();
        } else {
            darkTheme();
        }
    }, [theme, lightTheme, darkTheme])

    return(
        <>
        <Welcome/>
        <About/>
        <Proyects/>
        <Skills/>
        <Contact/>
        </>
    )
}

export default PageHome;