import './NavBar.scss';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';

const NavBar = () => {
    const {theme, darkTheme, lightTheme} = useContext(ThemeContext);

    useEffect(() => {
        if(theme) {
            lightTheme();
        } else {
            darkTheme();
        }
    }, [theme, lightTheme, darkTheme])

    const [submenu, setSubmenu] = useState('none');

    const toggleSubmenu = () => {
        if(submenu === 'none') {
            setSubmenu('block');
        } else {
            setSubmenu('none')
        }
    }

    return (
        <header>
            <nav>
                <div className='logo'>
                    <Link to='/'>
                        <img src='./assets/images/logo.svg' alt='logo de la pagina'/>
                    </Link>
                </div>
                <div className='nav'>
                    <ul className='nav__menu'>
                        <Link to='/' className='nav__menu__item'><li>Fredy Rodrigo</li></Link>
                        <Link to='/portafolio' className='nav__menu__item'><li>Portafolio</li></Link>
                        <Link to='/contacto' className='nav__menu__item'><li>Contacto</li></Link>
                        <div className='nav__menu__item item-links'>
                            <li onClick={toggleSubmenu}>Links</li>
                                <ul className='item-links__submenu' id='submenu' style={{display:submenu}}>
                                    <a href='https://www.linkedin.com/in/fredy-rodrigo/' target='_blank' rel='noreferrer' className='item-links__submenu__subitem'><li>Linkedin</li></a>
                                    <a href='https://github.com/Fredy-Rodrigo' target='_blank' rel='noreferrer' className='item-links__submenu__subitem'><li>Github</li></a>
                                    <a href='https://api.whatsapp.com/send?phone=51968097105&text=Hola, vengo de tu página web...' target='_blank' rel='noreferrer' className='item-links__submenu__subitem'><li>Whatsapp</li></a>
                                </ul>
                        </div>
                    </ul>
                </div>
            </nav>
        </header>
    )
}

export default NavBar;