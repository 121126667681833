import './ProjectDesc.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons'; 
import { useContext, useEffect } from 'react';
import { ThemeContext } from '../../context/ThemeContext';

const ProjectDesc = ({data}) => {
    const {theme, darkTheme, lightTheme} = useContext(ThemeContext);

    useEffect(() => {
        if(theme) {
            lightTheme();
        } else {
            darkTheme();
        }
    }, [theme, lightTheme, darkTheme])

    return (
        <div className='project-desc'>
            <h3>{data.title}</h3>
            <p>{data.desc}</p>
            <p>Tecnologías usadas:</p>
            <div className='project-desc__tecs'>
                <FontAwesomeIcon icon={data.tecs[0]} className='tec gray-icon'/>
                <FontAwesomeIcon icon={data.tecs[1]} className='tec gray-icon'/>
                <FontAwesomeIcon icon={data.tecs[2]} className='tec gray-icon'/>
                <FontAwesomeIcon icon={data.tecs[3]} className='tec gray-icon'/>
                <FontAwesomeIcon icon={data.tecs[4]} className='tec gray-icon'/>
            </div>
            <div className='project-desc__links'>
                <a href={data.repo} target='_blank' rel='noreferrer' className='link hover-text'>
                    <FontAwesomeIcon icon={faGithub} className='link__icon'/>
                    Repositorio
                </a>
                <a href={data.link} target='_blank' rel='noreferrer' className='link hover-text'>
                    <FontAwesomeIcon icon={faLink} className='link__icon'/>
                    Enlace
                </a>
            </div>
        </div>
    )
}

export default ProjectDesc;